<template>
  <div>
    <application-process-info-general :p_processInfo="d_processInfo"></application-process-info-general>
  </div>
</template>

<script>
import {
  default as ApplicationProcessInfoGeneral
} from '@/components/widgets/ApplicationProcessInfoGeneral';

import {
  default as router
} from '@/router';
import { mapGetters } from 'vuex';
import store from '@/store';
import moment from 'moment';

export default {
  name: 'WanalyzerAppProcessList',
  components: {
    ApplicationProcessInfoGeneral
  },
  computed: {
    ...mapGetters({
      device: 'device'
    })
  },
  data () {
    return {
      d_fullScreenNodeList: false,
      d_fullScreenTable: false,
      d_selectedNodeIndex: '',
      d_processInfo: {
        'cluster_name': 'wanalyzer',
        'show': false,
        'timer': 0,
        'last': '',
        'list': []
      }
    }
  },
  created: function () {
    this.user = JSON.parse(localStorage.getItem('user'));
  },
  mounted: function () {
  },
  methods: {},
  watch: {}
}

</script>
